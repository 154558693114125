import Joi from "joi";

export const get = Joi.object({
    date_start: Joi.date()
        .required(),
    date_end: Joi.date()
        .required(),
});

export const workHours = Joi.object({
    date: Joi.date()
        .required(),
    hours: Joi.array()
        .items(
            Joi.string()
            .regex(/^([0-9]{2})\:([0-9]{2})$/)
        )
        .unique()
        .required(),
});

export const event = Joi.object({
    date: Joi.date()
        .required(),
    name: Joi.string()
        .required()
        .trim(),
    description: Joi.string()
        .optional()
        .trim()
        .allow(null, ""),
    hour_start: Joi.date()
        .required(),
    hour_end: Joi.date()
        .required(),
});


export const eventCreate = Joi.object({
    name: Joi.string()
        .min(4)
        .max(255)
        .required(),
    date: Joi.date()
        .required(),
    start_hour: Joi.string()
        .regex(/^([0-9]{1,2})\:([0-9]{1,2})$/)
});