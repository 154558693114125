<template>
        <div>
            <Card>
                <v-row>
                    <v-col class="text-left">
                        <v-btn
                            :disabled="!calendarLoaded"
                            icon
                            @click="$refs.calendar.prev()"
                        >
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-col>

                    <v-col class="text-center" v-if="calendarLoaded">
                        {{ $refs.calendar.title }}
                    </v-col>

                    <v-col class="text-center" v-else>
                        <v-progress-circular
                            :size="30"
                            color="primary"
                            indeterminate
                        />
                    </v-col>

                    <v-col class="text-right">
                        <v-btn
                            :disabled="!calendarLoaded"
                            icon
                            @click="$refs.calendar.next()"
                        >
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </Card>

            <Card>
                <v-calendar
                    ref="calendar"
                    color="primary"
                    v-model="value"
                    :weekdays="weekday"
                    :type="type"
                    :locale="this.$i18n.locale"
                    @change="onChange"
                    @click:day="onDayClick"
                >
                    <template v-slot:day-label="{ past, day, date }">
                        <div class="day" :set="entry = getEntry(date)">
                            <v-avatar
                                :color="(date == selectedDate) ? 'green' : (date == today) ? 'blue-grey lighten-3' : 'white'"
                                size="42"
                                class="mt-3 mb-3"
                            >
                                <span :class="{ 'white--text' : date == selectedDate || date == today }">{{day}}</span>
                            </v-avatar>

                            <div class="info1" v-if="calendarLoaded">
                                <v-icon v-if="entry && entry.workHours.length"  class="ml-2" size="18" color="green">mdi-check</v-icon>
                                <v-icon v-else-if="!past" class="ml-2" size="18" color="red">mdi-close</v-icon>
                            </div>

                            <div class="info2" v-if="calendarLoaded && entry && entry.events.length > 0">
                                {{entry.events.length}} <div class="dot"></div>
                            </div>
                        </div>
                    </template>
                </v-calendar>
            </Card>

            <div v-if="selectedDate">
                <Card class="mt-3" :title="$t('calendar.work_hours.title')" :updateTime="updateTime" :saveProgress="saveProgress" @save="setWorkHours()">
                    <template v-slot:headerActions>
                        <v-icon size="28">mdi-calendar-range</v-icon> {{formattedSelectedDate}}
                    </template>

                    <v-select
                        v-model="formWorkHours.hours"
                        @change="changeWorkHours"
                        :items="hours"
                        :label="$t('calendar.work_hours.hours')"
                        :no-data-text="$t('calendar.work_hours.no_hours')"
                        :error-messages="errorsWorkHours.hours"
                        :hide-details="!errorsWorkHours.hours"
                        prepend-inner-icon="mdi-clock"
                        :menu-props="{ maxWidth: '200' }"
                        outlined
                        deletable-chips
                        small-chips
                        multiple
                        clearable
                        hide-selected
                    />
                </Card>

                <Card class="mt-3" :title="$t('calendar.events.title')">
                    <template v-slot:headerActions>
                        <v-icon size="28">mdi-calendar-range</v-icon> {{formattedSelectedDate}}
                    </template>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>{{$t('calendar.list.subject')}}</th>
                        
                                <th>{{$t('calendar.list.date_start')}}</th>
                            
                                <th>{{$t('calendar.list.date_end')}}</th>

                                <th class="w-action">{{$t('calendar.list.action')}}</th>
                            </tr>

                        </thead>
                        <tbody style="white-space: nowrap;">
                            <tr v-for="(e, index) in events" :key="index">
                                <td class="pt-3 pb-3">
                                    <span class="font-weight-bold">
                                        {{ (e.name) ? e.name : (e.patient) ? $t("call.list.patient")+": "+e.patient.name : $t("call.no_name") }}
                                    </span>
                                </td>
                                <td>
                                    <tr>
                                        <v-icon class="mr-1">mdi-calendar</v-icon>
                                        {{ (new Date(e.start)).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"}) }}
                                        {{ (new Date(e.start)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' }) }} 
                                    </tr>
                                </td>
                                <td>
                                    <tr v-if="e.type != 2">
                                        {{ (new Date(e.end)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' }) }}
                                        {{ endTime(e.start, e.end) }}
                                    </tr>
                                </td>
                                <td class="w-action">
                                    <v-btn
                                        v-if="e.type == 2"
                                        text
                                        color="error"
                                        @click="remove(e._id)" 
                                    >
                                        <v-icon>mdi-minus-circle-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="e.type == 1 || e.type == 0"
                                        fab
                                        color="primary"
                                        x-small
                                        dark
                                        depressed
                                        :to="{ name: 'call', params: { id: e.call, routefrom: 'calendar' } }"
                                    >
                                        <v-icon>mdi-cog</v-icon>
                                    </v-btn>
                                </td>
                            </tr>  
                        </tbody>
                    </v-simple-table>
                    
                    <template v-slot:actions>
                        <v-btn 
                            class="pr-3" 
                            @click="dialog = true" 
                            color="primary"
                        >
                            <v-icon left>mdi-plus</v-icon> {{ $t('calendar.events.add')}}
                        </v-btn> 
                    </template>
                </Card>
            </div>
    
            <EventCreate 
                v-if="dialog" 
                :date="new Date(selectedDate)"
                :open="dialog"
                :user="user" 
                @cancel="dialog = false" 
                @save="load()" 
            />

            <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
        </div>
</template>

<style lang="scss" scoped>
    .day{
        position: relative;

        .info1{
            position: absolute;
            top: 0px;
            left: 0px;
        }

        .info2{
            position: absolute;
            top: 2px;
            right: 6px;
            font-size: 14px;
            color: grey;

            .dot{
                display: inline-block;
                width: 10px;
                height: 10px;
                background: rgb(72, 136, 196);
                border-radius: 50%;
            }
        }
    }
</style>

<script>
import Card from "./Card.vue";
import EventCreate from "../dialogs/EventCreate.vue"
import validator from "../../plugins/validator";
import * as calendarValidation from "../../api/calendar/validation";
import { string } from 'joi';
import SaveSnackBar from "./SaveSnackBar.vue";

export default {
    components: {
        Card,
        EventCreate,
        SaveSnackBar
    },

    mounted() {
        this.today = this.formatDateToCalendar(new Date());
        this.hours = this.generateHours(this.fromHour, this.toHour, this.everyMinutes);
    },
    props:{
        user: {
            type: string
        }
    },
    data: () => ({
        calendarLoaded: false,
        saveProgress: false,

        interval: null,

        fromHour: 7,
        toHour: 20,
        everyMinutes: 30,
        hours: [],
        selectedHours: [],
        updateTime: null,

        form: {
            date_start: null,
            date_end: null
        },

        formWorkHours:{
            date: null,
            hours: []
        },

        errorsWorkHours:{
            date: null,
            hours: null
        },

        type: 'month',
        mode: 'stack',
        weekday: [1, 2, 3, 4, 5, 6, 0],

        value: '',
        today: null,

        selectedDate: '',

        entries: [],
        events: [],

        dialog: false,
        save_snackbar: false
    }),

    computed: {
        formattedSelectedDate(){
            return new Date(this.selectedDate).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"});
        }
    },

    methods: {
        validateWorkHours() {
            Object.assign(this.$data.errorsWorkHours, this.$options.data().errorsWorkHours);

            return validator(
                this.formWorkHours,
                calendarValidation.workHours,
                function(key, type, limit) {
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errorsWorkHours[key] = text;
                }.bind(this)
            );
        },

        async load() {
            this.calendarLoaded = false;
            if(this.user) this.form.user = this.user;
            const res = await this.$store.dispatch("api/calendar/get", this.form);

            if (res.status == 200) {
                this.entries = res.data;
                this.calendarLoaded = true;

                for(let e of this.entries){
                    e.date = this.formatDateToCalendar(new Date(e.date));

                    if(e.date == this.selectedDate){
                        this.updateFromEntry(e);
                    }
                }
            }
        },

        async setWorkHours(){
            if(!this.validateWorkHours()) return;
            if(this.user) this.formWorkHours.user = this.user;
            
            this.saveProgress = true;
            const res = await this.$store.dispatch("api/calendar/workHours", this.formWorkHours);
            this.saveProgress = false;
            this.save_snackbar = true;
            
            if(res.status == 200){
                await this.load();
            }
            
            delete this.formWorkHours.user;
        },

        async onChange({ start, end }){
            this.form.date_start = new Date(start.date);
            this.form.date_end = new Date(end.date);

            this.form.date_start = this.form.date_start.setDate(this.form.date_start.getDate() - 6);
            this.form.date_end = this.form.date_end.setDate(this.form.date_end.getDate() + 6);
            
            await this.load();

            if(!this.selectedDate){
                this.onDayClick({
                    date: this.today
                });
            }
        },

        onDayClick(e){
            this.selectedDate = e.date;
            this.updateTime = null;

            Object.assign(this.$data.formWorkHours, this.$options.data().formWorkHours);

            this.formWorkHours.date = new Date(this.selectedDate);

            this.events = [];
            let entry = this.getEntry(this.selectedDate);
            if(entry) this.updateFromEntry(entry);
        },

        formatDateToCalendar(d){
            return d.getFullYear() + '-' + String(d.getMonth() + 1).padStart(2, '0') + '-' + String(d.getDate()).padStart(2, '0');
        },

        getEntry(date){
            return this.entries.find((e) => e.date == date);
        },

        updateFromEntry(entry){
            this.formWorkHours.hours = entry.workHours;
            this.updateTime = new Date(entry.updateTime);
            this.events = entry.events;

            this.events.sort(function(a,b){
                return new Date(a.start) - new Date(b.start);
            });
        },

        changeWorkHours(){
            this.formWorkHours.hours.sort(function(a, b){
                if(a < b) { return -1; }
                if(a > b) { return 1; }
                return 0;
            });
        },

        generateHours(from, to, every){
            const hours = [];

            for(let i = from; i <= to; i++){
                let times = Math.ceil(60 / every);

                if(i == to) times = 1;

                for(let m = 0; m < times; m++){
                    let mins = m * every;

                    hours.push(String(i).padStart(2, "0")+":"+String(mins).padStart(2, "0"));
                }
            }

            return hours;
        },

        endTime(start, end){
            let time,
                diff = (new Date(end).getTime() - new Date(start).getTime())/1800000;
            if(diff<2){
                time =  " (" + diff*30 + this.$t('call.min') +")";
            }else{
                time = " (" + 30*diff/60 + this.$t('call.hour') + ")";
            }
            return time;
        },

        
        status(status){
            if (status == "idle" ) return "text-right blue-grey--text text--darken-1";
            if (status == "during" ) return "text-right light-blue--text text--darken-3";
            if (status == "end" ) return "text-right green--text text--darken-1";
            if (status == "fail" ) return "text-right red--text text--darken-2";
            if (status == "canceled" ) return "text-right red--text text--darken-2";
            return "text-right blue-grey--text text--darken-1";
        },

        async remove(id){
            let params = {
                id: id,
                entry: this.getEntry(this.selectedDate)._id
            }
            
            if(this.entity) params.entity = this.entity;
            let res =  await this.$store.dispatch("api/calendar/remove", params);
            if (res.status == 200) this.load();
        }
    }
};
</script>

