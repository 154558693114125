<template>
    <div>
        <v-row class="mt-0 pa-0">
            <v-col class="col-12 col-sm-4 pr-3 pr-sm-1 pt-0 pt-sm-0 pb-0">
                <v-select
                    v-model="date.year"
                    @change="update()"
                    :label="yearLabel"
                    :items="years"
                    :error="errorMessage != null && errorMessage.length > 0"
                    :hide-details="true"
                    :disabled="disabled"
                    item-text="value"
                    item-value="key"
                    outlined
                ></v-select>
            </v-col>
            
            <v-col class="col-12 col-sm-4 pl-3 pr-3 pl-sm-1 pr-sm-1 pr-sm-0 pt-sm-0 pb-0">
                <v-select
                    v-model="date.month"
                    @change="update()"
                    :label="monthLabel"
                    :items="months"
                    :error="errorMessage != null && errorMessage.length > 0"
                    :hide-details="true"
                    :disabled="disabled"
                    item-text="value"
                    item-value="key"
                    outlined
                ></v-select>
            </v-col>
            
            <v-col class="col-12 col-sm-4 pl-3 pl-sm-1 pr-3 pt-sm-0 pb-0">
                <v-select
                    v-model="date.day"
                    @change="update()"
                    :label="dayLabel"
                    :items="days"
                    :error="errorMessage != null && errorMessage.length > 0"
                    :hide-details="true"
                    :disabled="disabled"
                    item-text="value"
                    item-value="key"
                    outlined
                ></v-select>
            </v-col>
        </v-row>

        <div
            v-if="errorMessage != null && errorMessage.length > 0 && !hideDetails"
            class="text-caption text-left error--text pl-3 pt-1 pr-2 mt-3"
        >
            {{errorMessage}}
        </div>
    </div>
</template>

<script>
export default {
    props:{
        fromYear:{
            type: Number,
            default: 1900
        },

        toYear:{
            type: Number,
            default: new Date().getFullYear()
        },

        yearLabel: {
            type: String,
            default: null
        },

        monthLabel: {
            type: String,
            default: null
        },

        dayLabel: {
            type: String,
            default: null
        },

        errorMessage: {
            type: String,
            default: null
        },

        disabled:{
            type: Boolean,
            default: false
        },

        hideDetails:{
            type: Boolean,
            default: false
        },

        date: {
            type: Object,
            default: () => {
                return {
                    year: null,
                    month: null,
                    day: null,
                }
            }
        }
    },

    model: {
        prop: 'date',
        event: 'change'
    },

    mounted() {
        this.update();
    },

    data: () => ({
        years: [],
        months: [],
        days: []
    }),

    methods: {
        update(){
            this.years = [];
            this.months = [];
            this.days = [];

            for(let y = this.toYear; y >= this.fromYear; y--){
                this.years.push({
                    key: y,
                    value: y
                });
            }

            for(let m = 1; m <= 12; m++){
                this.months.push({
                    key: m,
                    value: String(m).padStart(2, '0')
                });
            }

            let daysInMonth = new Date(this.date.year, this.date.month, 0).getDate();

            for(let d = 1; d <= daysInMonth; d++){
                this.days.push({
                    key: d,
                    value: String(d).padStart(2, '0')
                });
            }

            if(this.date.day > daysInMonth) this.date.day = null;

            this.$emit("input", this.date);
        }
    }
};
</script>
