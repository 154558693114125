<template>
    <Page :avatar="form.email" :title="form.name" :progress="progress">

         <v-tabs class="elevation-1">
            <v-tab @click="tab = 0">{{$t('user.tabs.main')}}</v-tab>
            <v-tab @click="tab = 1">{{$t('user.tabs.calendar')}}</v-tab>
        </v-tabs>

        <template v-if="tab == 0">
            <ActionBar :updateTime="updateTime" class="mt-3">
                <v-btn 
                    color="primary"
                    @click="save()"
                    :loading="saveProgress" 
                    :disabled="saveProgress || dangerProgress"
                >
                    <v-icon left>mdi-content-save-outline</v-icon> {{$t("save")}}
                </v-btn>
            </ActionBar>

            <Card class="mt-3" :title="$t('user.update.title')">
                <v-row>
                    <v-col class="col-12 pt-0 pb-0">
                        <v-select
                            class="mt-3"
                            v-if="roles.length > 0"
                            v-model="roleSelect"
                            :items="roles"
                            item-text="name"
                            item-value="_id"
                            :label="$t('user.form.role')"
                            hide-details="true"
                            :disabled="saveProgress || dangerProgress"
                            persistent-hint
                            outlined
                            prepend-inner-icon="mdi-account-group"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="col-12 col-md-8 pt-0 pb-0">
                        <v-text-field
                            class="mt-3"
                            :label="$t('user.form.name')"
                            v-model="form.name"
                            :error-messages="errors.name"
                            :hide-details="!errors.name"
                            :disabled="saveProgress || dangerProgress"
                            @change="validate('name')"
                            outlined
                            prepend-inner-icon="mdi-account"
                        />
                    </v-col>

                    <v-col class="col-12 col-md-4 pt-0 pb-0">
                        <v-text-field
                            class="mt-3"
                            :label="$t('user.form.title')"
                            v-model="form.title"
                            :error-messages="errors.title"
                            :hide-details="!errors.title"
                            :disabled="saveProgress || dangerProgress"
                            @change="validate('title')"
                            outlined
                            prepend-inner-icon="mdi-school"
                        />
                    </v-col>
                </v-row>

                <v-row class="mt-3">
                    <v-col class="col-12 col-md-6 pt-0 pb-0">
                        <v-text-field
                            class="mt-3"
                            :label="$t('user.form.email')"
                            v-model="form.email"
                            :error-messages="errors.email"
                            :hide-details="!errors.email"
                            :disabled="saveProgress || dangerProgress"
                            @change="validate('email')"
                            outlined
                            prepend-inner-icon="mdi-email"
                        />
                    </v-col>

                    <v-col class="col-12 col-md-6 pt-0 pb-0">
                        <v-text-field
                            class="mt-3"
                            :label="$t('user.form.phone')"
                            v-model="form.phone"
                            :error-messages="errors.phone"
                            :hide-details="!errors.phone"
                            :disabled="saveProgress || dangerProgress"
                            @change="validate('phone')"
                            outlined
                            prepend-inner-icon="mdi-phone"
                        />
                    </v-col>
                </v-row>

                <v-row class="mt-3 mb-0">
                    <v-col class="col-12 col-md-6 pt-0 pb-0">
                        <v-text-field
                            class="mt-3"
                            :label="$t('user.form.address')"
                            v-model="form.address"
                            :error-messages="errors.address"
                            :hide-details="!errors.address"
                            :disabled="saveProgress || dangerProgress"
                            @change="validate('address')"
                            outlined
                            prepend-inner-icon="mdi-map-marker"
                        />
                    </v-col>
                    
                    <v-col class="col-12 col-md-6 pt-0 pb-0">
                        <v-text-field
                            class="mt-3"
                            v-model="form.password"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            :label="$t('user.form.password.password')"
                            counter
                            :error-messages="errors.password"
                            :hide-details="!errors.password"
                            :disabled="saveProgress || dangerProgress"
                            @change="validate('password')"
                            @click:append="show = !show"
                            outlined
                            prepend-inner-icon="mdi-lock"
                            autocomplete="new-password"
                        />
                    </v-col>
                </v-row>
            </Card>

            <DangerZone
                v-if="user.id != form.id"
                @btnClick="beforeDangerZoneAction()"
                v-model="dialogDangerItem"
                :data="[
                    {
                        itemKey: 'changeStatus',
                        itemName: $t('user.danger_zone.change_status'),
                        itemInfo: $t('user.danger_zone.change_status_info') + ' ',
                        itemInfoGreen: (form.enabled ? $t('active') : null),
                        itemInfoRed: (form.enabled ? null : $t('inactive')),
                        itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'changeStatus' ? dangerProgress : false)
                    },

                    {
                        itemKey: 'delete',
                        itemName: $t('user.danger_zone.delete'),
                        itemInfo: $t('user.danger_zone.delete_info'),
                        itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'delete' ? dangerProgress : false)
                    }
                ]"
                :disabled="saveProgress || dangerProgress"
            />

            <DangerZoneConfirm
                v-if="dialogDanger"
                :title="() => {switch (dialogDangerItem.itemKey) {
                    case 'changeStatus': 
                        return $t('user.danger_zone.change_status');
                        break;
                    case 'delete':
                        return $t('user.danger_zone.delete');
                }}"
                :description="() => {switch (dialogDangerItem.itemKey) {
                    case 'changeStatus': 
                        return $t('user.danger_zone.dialog.change_status_desc');
                        break;
                    case 'delete':
                        return $t('user.danger_zone.dialog.delete_desc');
                }}"
                :descriptionContent="() => {switch (dialogDangerItem.itemKey) {
                    case 'changeStatus': 
                        return (form.enabled ? $t('inactive') : $t('active'));
                        break;
                    case 'delete':
                        return form.name;
                }}"
                :checkText="() => form.email"
                :dependenciesData="dependenciesData" 
                :open="dialogDanger"
                :save="dialogDangerItem.itemName"
                :saveProgress="dangerDialogSaveProgress"
                @cancel="dialogDanger = false" 
                @save="dangerZoneAction(dialogDangerItem)"
            />
        </template>
        <template v-if="tab == 1">
            <Calendar class="mt-3" :user="form.id"/>
        </template>

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import Card from "./components/Card.vue";
import DangerZone from "./components/DangerZone.vue";
import DangerZoneConfirm from "./dialogs/DangerZoneConfirm.vue";
import Calendar from "./components/Calendar.vue"
import validator from "../plugins/validator";
import * as userValidation from "../api/user/validation";
import SaveSnackBar from "./components/SaveSnackBar.vue";

export default {
    components: {
        Page,
        ActionBar,
        Card,
        DangerZone,
        DangerZoneConfirm,
        Calendar,
        SaveSnackBar
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.load();
    },

    data: (vm) => ({
        form: {
            id: null,
            name: null,
            title: null,
            email: null,
            phone: null,
            address: null,
            password: null,
            enabled: null,
        },

        errors: {
            name: null,
            title: null,
            email: null,
            phone: null,
            address: null,
            password: null
        },

        dialogDanger: false,
        dialogDangerItem: null,
        dependenciesData: null,

        updateTime: null,
        show: false,
        progress: true,
        saveProgress: false,
        dangerProgress: false,
        dangerDialogSaveProgress: false,

        roles: [],
        roleSelect: null,
        save_snackbar: false,

        tab: 0
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {

            Object.assign(this.$data.errors, this.$options.data(this).errors);

            const res = await this.$store.dispatch("api/user/get", { id: this.form.id });

            if (res.status == 200) {
                if(this.user.hasPerm("role")){
                    let form = null;
                    if(this.$store.state.api.user.selectedEntity) form = { entity: this.$store.state.api.user.selectedEntity._id };
                    
                    const res_r = await this.$store.dispatch("api/role/all", form);
                    
                    if (res_r.status == 200) {
                        this.roles = res_r.data;
                        this.roleSelect = res.data.role;
                    }
                }

                this.form.name = res.data.name;
                this.form.title = res.data.title;
                this.form.email = res.data.email;
                this.form.phone = res.data.phone;
                this.form.address = res.data.address;
                this.form.password = null;
                this.form.enabled = res.data.enabled;
                this.updateTime = res.data.updateTime ? new Date(res.data.updateTime) : null;
                this.progress = false;
            }
        },

        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            return validator(
                this.form,
                userValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async save() {
            if (!this.validate()) return;
            if(this.user.hasPerm("role")) this.form.role = this.roleSelect;

            this.saveProgress = true;
            await this.$store.dispatch("api/user/update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        async beforeDangerZoneAction() {
            this.dangerProgress = true;
            await this.load();
            this.dependenciesData = null;
            this.dangerProgress = false;
            this.dialogDanger = true;
        },

        async dangerZoneAction(item) {
            switch (item.itemKey) {
                case "delete":
                    this.dangerDialogSaveProgress = true;
                    this.dependenciesData = null;
                    const res = await this.$store.dispatch("api/user/delete", { id: this.form.id });
                    if (res.status==405) {
                        this.dependenciesData = res.data.data;
                        this.dangerDialogSaveProgress = false;
                        return;
                    }
                    if (res.status==200) {
                        this.$router.push({ name: 'users' })
                    }
                    break;
                case "changeStatus":
                    this.dangerDialogSaveProgress = true;
                    let tempForm = Object.assign({}, this.form);
                    tempForm.enabled = !this.form.enabled;
                    await this.$store.dispatch("api/user/update", tempForm);
                    await this.load();
                    this.dialogDanger = false;
                    this.dangerDialogSaveProgress = false;
                    break;
            }
        }
    }
};
</script>
