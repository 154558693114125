<template>
    <Dialog :title="$t('calendar.event_create.title')" :open="open" @cancel="$emit('cancel')" @save="save()">
        <v-row>
            <v-col class="pb-0 col-8">    
                <v-text-field
                    :label="$t('calendar.event_create.name')"
                    v-model="form.name"
                    :error-messages="errors.name"
                    :hide-details="!errors.name"
                    @change="validate('name')"
                    outlined
                    prepend-inner-icon="mdi-calendar-edit"
                />
            </v-col>
            <v-col class="col-4">
                <v-select
                    v-model="form.start_hour"
                    :items="time"
                    :label="$t('calendar.event_create.start')"
                    menu-props="auto"
                    :hide-details="true"
                    prepend-inner-icon="mdi-clock"
                    outlined
                ></v-select>
            </v-col>
        </v-row>
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";
import DateInput from '../components/DateInput.vue';

import validator from "../../plugins/validator";
import * as eventValidation from "../../api/calendar/validation";
import { string } from 'joi';

export default {
    components: {
        Dialog,
        DateInput
    },

    async mounted() {
        this.generateStartTime();
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        entity: {
            type: String,
            default: null
        },

        date: {
            type: Date,
            default: null
        },

        user: {
            type: String
        }


    },

    data: () => ({

        form: {
            name: null,
            date: null,
            start_hour: null
        },

        errors: {
            name: null,
            date: null,
            start_hour: null,
        },

        time: []
    }),

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            return validator(
                this.form,
                eventValidation.eventCreate,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        generateStartTime(){
            for(let i=0; i<24; i++) {
                for(let j=0; j<2; j++) {
                    this.time.push(i + ":" + (j===0 ? "00" : 30*j) );
                }
            }
            let d = new Date(),
                h = d.getHours(),
                m = 30 * Math.floor(d.getMinutes()/30),
                stamp = h + ":" + (m === 0 ? "00" : m);
            let pos = this.time.indexOf(stamp)
            this.form.start_hour = this.time[pos+1];
        },

        async save() {
            this.form.date = this.date;
            if(!this.validate()) return
            if(this.entity) this.form.entity = this.entity;
            if(this.user) this.form.user = this.user;
            const [hour, min] = this.form.start_hour.split(":");
            this.form.date = new Date(this.date).setHours(hour, min, 0, 0);
            let res = await this.$store.dispatch("api/calendar/addEvent", this.form);
            if (res.status == 200) {
                this.$emit('save');
                this.$emit('cancel');
            }
        },
    }
};
</script>
